<template>
  <div class="GameCollapse" :style="isShowTitleBlock ? '' : 'border-bottom:0px;'">
    <div v-if="isShowTitleBlock" class="collapseTitleBlock" @click="clickArrow">
      <div class="leftArrowBlock">
        <i :class="arrowIconJudge" />
      </div>
      <div class="rightLeagueNameBlock">{{ source.LeagueNameStr }}</div>
    </div>
    <template v-if="!isCollapse">
      <!-- 波膽 -->
      <template v-if="isBoldGame">
        <div
          v-for="(teamData, teamIndex) in boldTeamData"
          :key="`${teamIndex}`"
          class="boldTablePanel"
        >
          <div class="boldTableTitleBlock">
            <div class="timeBlock">
              <div class="timeText">{{ $lib.timeFormatMMDD(teamData.ScheduleTimeStr) }}</div>
              <div class="timeText">{{ $lib.timeFormatHHmm(teamData.ScheduleTimeStr) }}</div>
            </div>
            <div class="teamBlock">
              <div class="teamText" v-if="teamData.hasOwnProperty('HomeTeamStr')">
                <!-- team image -->
                <template v-if="teamImages.includes(selectCatID)">
                  <img
                    v-if="teamData.HomeImage"
                    class="team-image"
                    :src="teamImagePath + teamData.HomeImage"
                    onerror="javascript:this.src='/img/no.png'"
                  />
                  <img v-else class="team-image" src="@/assets/img/no.png" />
                </template>
                {{ teamData.HomeTeamStr }}</div
              >
              <div class="teamText">vs</div>
              <div class="teamText" v-if="teamData.hasOwnProperty('AwayTeamStr')">
                <!-- team image -->
                <template v-if="teamImages.includes(selectCatID)">
                  <img
                    v-if="teamData.AwayImage"
                    class="team-image"
                    :src="teamImagePath + teamData.AwayImage"
                    onerror="javascript:this.src='/img/no.png'"
                  />
                  <img v-else class="team-image" src="@/assets/img/no.png" />
                </template>
                {{ teamData.AwayTeamStr }}</div
              >
            </div>
            <div
              class="star"
              :class="starCSSJudge(teamData.EvtID)"
              @click="addFavoriteHandler(teamData.EvtID)"
            ></div>
            <img
              v-if="teamData.Live"
              class="live"
              src="@/assets/img/common/btn_GDV_live.svg"
              @click="moreGameClickHandler(teamData)"
            />
          </div>
          <div class="boldTableBetList">
            <div class="boldTableBetListLeftContainer">
              <div
                class="boldTableBetBlock"
                v-for="(OULine, OULineIndex) in boldRenderLeftTemplateFilter"
                :style="boldFilterType ? '' : 'width:16.6666%'"
                :class="boldTableBetBlockIsSelect(teamData.boldOddMapData[OULine], OULine)"
                :key="OULineIndex"
                @click="
                  goBoldBet(
                    boldOddMapDataDrewOdds(teamData.boldOddMapData[OULine]),
                    teamData.boldOddMapData[OULine],
                    teamData,
                    $event
                  )
                "
              >
                <div class="betBlockTop">{{ OULine }} </div>
                <div class="betBlockBottom">
                  {{ boldOddMapDataDrewOdds(teamData.boldOddMapData[OULine]) }}
                </div>
              </div>
            </div>
            <div class="boldTableBetListRightContainer">
              <div
                class="boldTableBetBlock"
                v-for="(OULine, OULineIndex) in boldRenderRightTemplateFilter"
                :class="boldTableBetBlockIsSelect(teamData.boldOddMapData[OULine], OULine)"
                :style="boldFilterType ? '' : 'width:20%'"
                :key="OULineIndex"
                @click="
                  goBoldBet(
                    boldOddMapDataDrewOdds(teamData.boldOddMapData[OULine]),
                    teamData.boldOddMapData[OULine],
                    teamData,
                    $event
                  )
                "
              >
                <div class="betBlockTop">{{ boldOULineShow(OULine) }} </div>
                <div class="betBlockBottom">
                  {{ boldOddMapDataDrewOdds(teamData.boldOddMapData[OULine]) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 入球數 半全場 -->
      <template v-else-if="isFix">
        <table
          v-for="(teamData, teamIndex) in sourceTeamData"
          :key="teamIndex"
          class="normalTablePanel"
        >
          <tbody>
            <template v-if="teamData.EvtStatus === 1">
              <tr>
                <td class="FirstCatNameBlock">
                  <div class="leftTimeBlock">
                    <template v-if="selectGameType === 2">
                      <div class="timeRow light">
                        {{ teamData.TimeAct }}
                      </div>
                    </template>
                    <template v-else>
                      <div class="timeRow">
                        {{ $lib.timeFormatMMDD(teamData.ScheduleTimeStr) }}
                      </div>
                      <div class="timeRow">
                        {{ $lib.timeFormatHHmm(teamData.ScheduleTimeStr) }}
                      </div>
                    </template>
                  </div>
                  <div class="centerTeamBlock">
                    <div class="teamRow">
                      <!-- team image -->
                      <template v-if="teamImages.includes(selectCatID)">
                        <img
                          v-if="teamData.HomeImage"
                          class="team-image"
                          :src="teamImagePath + teamData.HomeImage"
                          onerror="javascript:this.src='/img/no.png'"
                        />
                        <img v-else class="team-image" src="@/assets/img/no.png" />
                      </template>
                      {{ teamData.HomeTeamStr }}
                    </div>
                    <div class="teamRow">
                      <!-- team image -->
                      <template v-if="teamImages.includes(selectCatID)">
                        <img
                          v-if="teamData.AwayImage"
                          class="team-image"
                          :src="teamImagePath + teamData.AwayImage"
                          onerror="javascript:this.src='/img/no.png'"
                        />
                        <img v-else class="team-image" src="@/assets/img/no.png" />
                      </template>
                      {{ teamData.AwayTeamStr }}
                    </div>
                  </div>
                  <div v-if="selectGameType === 2" class="scoreBlock">
                    <div
                      class="homeScore"
                      :class="{ light: teamData.HomeScore > teamData.AwayScore }"
                    >
                      {{ teamData.HomeScore }}
                    </div>
                    <div
                      class="awayScore"
                      :class="{ light: teamData.AwayScore > teamData.HomeScore }"
                    >
                      {{ teamData.AwayScore }}
                    </div>
                  </div>
                  <div class="rightFavoriteBlock">
                    <div
                      class="star"
                      :class="starCSSJudge(teamData.EvtID)"
                      @click="addFavoriteHandler(teamData.EvtID)"
                    />
                    <img
                      v-if="teamData.Live"
                      class="live"
                      src="@/assets/img/common/btn_GDV_live.svg"
                      @click="moreGameClickHandler(teamData)"
                    />
                  </div>
                </td>

                <td
                  v-for="(wagerData, wagerIndex) in teamData.Wager"
                  :key="wagerIndex"
                  class="GameTableHeaderOtherTD"
                  :set="
                    ((sportData = $SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, 0)),
                    (GameID = wagerRoIndexToGameID(wagerData, 0)))
                  "
                >
                  <div class="WagerList fix">
                    <template v-if="wagerData.isNoData">
                      <div class="WagerRow fix" />
                    </template>
                    <template v-else>
                      <div
                        class="WagerRow fix"
                        :class="isFixWagerSelectd(GameID)"
                        @click="
                          goFixBet(
                            $SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, 0)
                              .drewPlayOdd,
                            wagerData,
                            teamData,
                            $event
                          )
                        "
                      >
                        <div class="WagerCenterItem fix">
                          <Odd
                            v-if="sportData.drewPlayOdd > limit"
                            :OddValue="sportData.drewPlayOdd"
                            :UniqueID="`${GameID}`"
                          />
                        </div>
                      </div>
                    </template>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
      <template v-else>
        <table
          v-for="(teamData, teamIndex) in sourceTeamData"
          :key="teamIndex"
          class="normalTablePanel"
        >
          <tbody>
            <template v-if="teamData.EvtStatus === 1">
              <template v-for="(_, rowIndex) in teamData.Row">
                <tr v-if="isFixLineShow || isLineShow(rowIndex)" :key="`${teamIndex}-${rowIndex}`">
                  <!-- 冠軍 -->
                  <td
                    v-if="selectCatID === GAME_CHAMPION_CAT"
                    class="FirstCatNameBlock"
                    :class="'c' + selectCatID"
                  >
                    <div class="leftTimeBlock"></div>
                    <div class="centerTeamBlock">
                      <div class="teamRow" :title="teamData.HomeTeamStr">
                        <!-- team image -->
                        <img
                          v-if="teamData.HomeImage"
                          class="team-image"
                          :src="teamImagePath + teamData.HomeImage"
                          onerror="javascript:this.src='/img/no.png'"
                        />
                        <img v-else class="team-image" src="@/assets/img/no.png" />
                        {{ teamData.HomeTeamStr }}
                      </div>
                    </div>
                  </td>
                  <td v-else class="FirstCatNameBlock" :class="'c' + selectCatID">
                    <div class="leftTimeBlock">
                      <template v-if="(rowIndex === 0 && selectGameType !== 2) || isSingleScore">
                        <div class="timeRow">
                          {{ $lib.timeFormatMMDD(teamData.ScheduleTimeStr) }}
                        </div>
                        <div class="timeRow">
                          {{ $lib.timeFormatHHmm(teamData.ScheduleTimeStr) }}
                        </div>
                      </template>
                      <template v-else>
                        <div v-if="rowIndex === 0" class="timeRow light">
                          {{ teamData.TimeAct }}
                        </div>
                      </template>
                    </div>
                    <div class="centerTeamBlock">
                      <!-- 顯示雙打1 -->
                      <template
                        v-if="
                          teamData.hasOwnProperty('HomePtAndNation') &&
                          teamData.HomePtAndNation.length !== 0
                        "
                      >
                        <div
                          class="teamRow"
                          :title="`${teamData.HomePtAndNation[0]}-${teamData.HomePtAndNation[1]} / ${teamData.HomePtAndNation[2]}-${teamData.HomePtAndNation[3]}`"
                        >
                          {{ teamData.HomePtAndNation[0] }}
                          <span class="teamPt">-{{ teamData.HomePtAndNation[1] }}</span>
                          <span> /</span>
                          {{ teamData.HomePtAndNation[2] }}
                          <span class="teamPt">-{{ teamData.HomePtAndNation[3] }}</span>
                        </div>
                      </template>
                      <!-- 顯示雙打2 -->
                      <template
                        v-if="
                          teamData.hasOwnProperty('AwayPtAndNation') &&
                          teamData.AwayPtAndNation.length !== 0
                        "
                      >
                        <div
                          class="teamRow"
                          :title="`${teamData.AwayPtAndNation[0]}-${teamData.AwayPtAndNation[1]} / ${teamData.AwayPtAndNation[2]}-${teamData.AwayPtAndNation[3]}`"
                        >
                          {{ teamData.AwayPtAndNation[0] }}
                          <span class="teamPt">-{{ teamData.AwayPtAndNation[1] }}</span>
                          <span> /</span>
                          {{ teamData.AwayPtAndNation[2] }}
                          <span class="teamPt">-{{ teamData.AwayPtAndNation[3] }}</span>
                        </div>
                      </template>
                      <!-- 只需要顯示一個隊伍 -->
                      <template
                        v-if="
                          teamData.hasOwnProperty('AwayTeamStr') && teamData.AwayTeamStr === '.'
                        "
                      >
                        <div
                          v-if="teamData.hasOwnProperty('HomeTeamStr')"
                          class="teamRow"
                          :title="teamData.HomeTeamStr"
                        >
                          <!-- team image -->
                          <template v-if="teamImages.includes(source?.CatID)">
                            <img
                              v-if="teamData.HomeImage"
                              class="team-image"
                              :src="teamImagePath + teamData.HomeImage"
                              onerror="javascript:this.src='/img/no.png'"
                            />
                            <img v-else class="team-image" src="@/assets/img/no.png" />
                          </template>
                          {{ teamData.HomeTeamStr }}
                          <span
                            v-if="teamData.hasOwnProperty('HomePtNameStr') && isShowPTName"
                            class="teamPt"
                            >{{ teamData.HomePtNameStr }}</span
                          >
                        </div>
                      </template>
                      <!-- 只需要顯示一個隊伍 -->
                      <template
                        v-else-if="
                          teamData.hasOwnProperty('HomeTeamStr') &&
                          teamData.hasOwnProperty('AwayTeamStr') &&
                          teamData.HomeTeamStr === '.'
                        "
                      >
                        <div class="teamRow" :title="teamData.AwayTeamStr">
                          <!-- team image -->
                          <template v-if="teamImages.includes(source?.CatID)">
                            <img
                              v-if="teamData.AwayImage"
                              class="team-image"
                              :src="teamImagePath + teamData.AwayImage"
                              onerror="javascript:this.src='/img/no.png'"
                            />
                            <img v-else class="team-image" src="@/assets/img/no.png" />
                          </template>
                          {{ teamData.AwayTeamStr }}
                          <span
                            v-if="teamData.hasOwnProperty('AwayPtNameStr') && isShowPTName"
                            class="teamPt"
                            >{{ teamData.AwayPtNameStr }}</span
                          >
                        </div>
                      </template>
                      <template v-else>
                        <!-- 單隊總得分 -->
                        <template v-if="isSingleScore">
                          <div
                            v-if="
                              rowIndex % 2 === 0 &&
                              teamData.hasOwnProperty('HomeTeamStr') &&
                              teamData.hasOwnProperty('AwayTeamStr')
                            "
                            class="teamRow"
                            :title="teamData.HomeTeamStr"
                          >
                            <!-- team image -->
                            <img
                              v-if="teamData.HomeImage"
                              class="team-image"
                              :src="teamImagePath + teamData.HomeImage"
                              onerror="javascript:this.src='/img/no.png'"
                            />
                            <img v-else class="team-image" src="@/assets/img/no.png" />
                            {{ teamData.HomeTeamStr }}
                            <span
                              v-if="
                                teamData.hasOwnProperty('HomePtNameStr') &&
                                teamData.HomePtNameStr !== ''
                              "
                              class="teamPt"
                            >
                              -{{ teamData.HomePtNameStr }}
                            </span>
                          </div>
                          <div v-else class="teamRow" :title="teamData.AwayTeamStr">
                            <!-- team image -->
                            <img
                              v-if="teamData.AwayImage"
                              class="team-image"
                              :src="teamImagePath + teamData.AwayImage"
                              onerror="javascript:this.src='/img/no.png'"
                            />
                            <img v-else class="team-image" src="@/assets/img/no.png" />
                            <span v-if="teamData.hasOwnProperty('AwayTeamStr')">
                              {{ teamData.AwayTeamStr }}</span
                            >
                            <span
                              v-if="
                                teamData.hasOwnProperty('AwayPtNameStr') &&
                                teamData.AwayPtNameStr !== ''
                              "
                              class="teamPt"
                            >
                              -{{ teamData.AwayPtNameStr }}
                            </span>
                          </div>
                        </template>
                        <!-- 非單隊總分 -->
                        <template v-else-if="rowIndex === 0">
                          <!-- 主客場對調 -->
                          <template v-if="!teamData.SetFlag">
                            <div
                              v-if="teamData.hasOwnProperty('AwayTeamStr')"
                              class="teamRow"
                              :title="teamData.AwayTeamStr"
                            >
                              <!-- team image -->
                              <img
                                v-if="teamData.AwayImage"
                                class="team-image"
                                :src="teamImagePath + teamData.AwayImage"
                                onerror="javascript:this.src='/img/no.png'"
                              />
                              <img v-else class="team-image" src="@/assets/img/no.png" />
                              <span
                                class="team-name"
                                :class="{ emphasized: isEmphasizedTeam(teamData, 2) }"
                                >{{ teamData.AwayTeamStr }}</span
                              >
                              <span
                                v-if="
                                  teamData.hasOwnProperty('AwayPtNameStr') &&
                                  teamData.AwayPtNameStr !== '' &&
                                  isShowPTName
                                "
                                class="teamPt"
                              >
                                -{{ teamData.AwayPtNameStr }}
                              </span>
                            </div>
                            <div
                              v-if="teamData.hasOwnProperty('HomeTeamStr')"
                              class="teamRow"
                              :title="teamData.HomeTeamStr"
                            >
                              <!-- team image -->
                              <img
                                v-if="teamData.HomeImage"
                                class="team-image"
                                :src="teamImagePath + teamData.HomeImage"
                                onerror="javascript:this.src='/img/no.png'"
                              />
                              <img v-else class="team-image" src="@/assets/img/no.png" />
                              <span
                                class="team-name"
                                :class="{ emphasized: isEmphasizedTeam(teamData, 1) }"
                                >{{ teamData.HomeTeamStr }}</span
                              >
                              <span
                                v-if="
                                  teamData.hasOwnProperty('HomePtNameStr') &&
                                  teamData.HomePtNameStr !== '' &&
                                  isShowPTName
                                "
                                class="teamPt"
                              >
                                -{{ teamData.HomePtNameStr }}
                              </span>
                            </div>
                          </template>
                          <template v-else>
                            <div
                              v-if="teamData.hasOwnProperty('HomeTeamStr')"
                              class="teamRow"
                              :title="teamData.HomeTeamStr"
                            >
                              <!-- team image -->
                              <template v-if="teamImages.includes(source?.CatID)">
                                <img
                                  v-if="teamData.HomeImage"
                                  class="team-image"
                                  :src="teamImagePath + teamData.HomeImage"
                                  onerror="javascript:this.src='/img/no.png'"
                                />
                                <img v-else class="team-image" src="@/assets/img/no.png" />
                              </template>
                              <span
                                class="team-name"
                                :class="{ emphasized: isEmphasizedTeam(teamData, 1) }"
                                >{{ teamData.HomeTeamStr }}</span
                              >
                              <span
                                v-if="
                                  teamData.hasOwnProperty('HomePtNameStr') &&
                                  teamData.HomePtNameStr !== '' &&
                                  isShowPTName
                                "
                                class="teamPt"
                              >
                                -{{ teamData.HomePtNameStr }}
                              </span>
                            </div>
                            <div
                              v-if="teamData.hasOwnProperty('AwayTeamStr')"
                              class="teamRow"
                              :title="teamData.AwayTeamStr"
                            >
                              <!-- team image -->
                              <template v-if="teamImages.includes(source?.CatID)">
                                <img
                                  v-if="teamData.AwayImage"
                                  class="team-image"
                                  :src="teamImagePath + teamData.AwayImage"
                                  onerror="javascript:this.src='/img/no.png'"
                                />
                                <img v-else class="team-image" src="@/assets/img/no.png" />
                              </template>
                              <span
                                class="team-name"
                                :class="{ emphasized: isEmphasizedTeam(teamData, 2) }"
                                >{{ teamData.AwayTeamStr }}</span
                              >
                              <span
                                v-if="
                                  teamData.hasOwnProperty('AwayPtNameStr') &&
                                  teamData.AwayPtNameStr !== '' &&
                                  isShowPTName
                                "
                                class="teamPt"
                              >
                                -{{ teamData.AwayPtNameStr }}
                              </span>
                            </div>
                          </template>
                        </template>
                      </template>
                      <div v-if="teamData.hasDrewOdds && rowIndex === 0" class="teamRow">
                        {{ $t('Common.DrewOdd') }}
                      </div>
                    </div>
                    <div v-if="rowIndex === 0 && selectGameType === 2" class="scoreBlock">
                      <template v-if="teamData.SetFlag">
                        <div
                          class="homeScore"
                          :class="teamData.HomeScore > teamData.AwayScore ? 'light' : ''"
                        >
                          {{ teamData.HomeScore }}
                        </div>
                        <div
                          class="awayScore"
                          :class="teamData.AwayScore > teamData.HomeScore ? 'light' : ''"
                        >
                          {{ teamData.AwayScore }}
                        </div>
                      </template>
                      <template v-else>
                        <div
                          class="awayScore"
                          :class="teamData.AwayScore > teamData.HomeScore ? 'light' : ''"
                        >
                          {{ teamData.AwayScore }}
                        </div>
                        <div
                          class="homeScore"
                          :class="teamData.HomeScore > teamData.AwayScore ? 'light' : ''"
                        >
                          {{ teamData.HomeScore }}
                        </div>
                      </template>
                    </div>
                    <div v-if="rowIndex === 0 || isSingleScore" class="rightFavoriteBlock">
                      <div
                        class="star"
                        :class="starCSSJudge(teamData.EvtID)"
                        title="收藏"
                        @click="addFavoriteHandler(teamData.EvtID)"
                      />
                      <img
                        v-if="teamData.Live"
                        class="live"
                        src="@/assets/img/common/btn_GDV_live.svg"
                        title="直播"
                        @click="moreGameClickHandler(teamData)"
                      />
                    </div>
                  </td>
                  <!-- Game Odds -->
                  <!-- 冠軍 -->
                  <template v-if="selectCatID === GAME_CHAMPION_CAT">
                    <td
                      v-for="(wagerData, wagerIndex) in teamWagerDataFilterLimit(teamData)"
                      :key="wagerIndex"
                      :set="
                        ((sportData = $SportLib.WagerDataToShowData(
                          teamData.SetFlag,
                          wagerData,
                          rowIndex
                        )),
                        (isShowDrewOdd = teamData.hasDrewOdds && rowIndex === 0),
                        (GameID = wagerRoIndexToGameID(wagerData, rowIndex)))
                      "
                      class="GameTableHeaderOtherTD"
                    >
                      <div class="WagerList">
                        <template v-if="wagerData.isNoData">
                          <div class="WagerRow" />
                        </template>
                        <template v-else>
                          <div
                            class="WagerRow"
                            :class="
                              WagerRowIsSelectInCartCSS(
                                GameID,
                                sportData.topPlayOdd,
                                $SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                  .topWagerPos
                              )
                            "
                            @click="
                              goBet(
                                $SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                  .topPlayOdd,
                                teamData,
                                wagerData,
                                rowIndex,
                                $SportLib.WagerDataToShowData(teamData.SetFlag, wagerData, rowIndex)
                                  .topWagerPos,
                                $event
                              )
                            "
                          >
                            <div class="WagerCenterItem">
                              <Odd
                                v-if="sportData.topPlayOdd > limit"
                                :OddValue="sportData.topPlayOdd"
                                :UniqueID="`${GameID}-0`"
                              />
                            </div>
                          </div>
                        </template>
                      </div>
                    </td>
                    <td class="GameTableHeaderOtherTD">
                      <div class="WagerList">
                        <div class="WagerRow" />
                      </div>
                    </td>
                  </template>
                  <template v-else>
                    <td
                      v-for="(wagerData, wagerIndex) in teamWagerDataFilterLimit(teamData)"
                      class="GameTableHeaderOtherTD"
                      :key="wagerIndex"
                      :set="
                        ((sportData = $SportLib.WagerDataToShowData(
                          teamData.SetFlag,
                          wagerData,
                          rowIndex
                        )),
                        (isShowDrewOdd = teamData.hasDrewOdds && rowIndex === 0),
                        (GameID = wagerRoIndexToGameID(wagerData, rowIndex)))
                      "
                    >
                      <div class="WagerList">
                        <template v-if="wagerData.isNoData">
                          <template v-if="isShowDrewOdd">
                            <div class="WagerRow" />
                            <div class="WagerRow" />
                            <div class="WagerRow" />
                          </template>
                          <template v-else>
                            <div class="WagerRow" />
                            <div class="WagerRow" />
                          </template>
                        </template>
                        <template v-else>
                          <!-- No Cutline -->
                          <template v-if="sportData.layoutType === 'single'">
                            <div
                              class="WagerRow"
                              :class="
                                WagerRowIsSelectInCartCSS(
                                  GameID,
                                  sportData.topPlayOdd,
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    wagerData,
                                    rowIndex
                                  ).topWagerPos
                                )
                              "
                              @click="
                                goBet(
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    wagerData,
                                    rowIndex
                                  ).topPlayOdd,
                                  teamData,
                                  wagerData,
                                  rowIndex,
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    wagerData,
                                    rowIndex
                                  ).topWagerPos,
                                  $event
                                )
                              "
                            >
                              <div class="WagerCenterItem">
                                <Odd
                                  v-if="sportData.topPlayOdd > limit"
                                  :OddValue="sportData.topPlayOdd"
                                  :UniqueID="`${GameID}-0`"
                                />
                              </div>
                            </div>
                            <div
                              class="WagerRow"
                              :class="
                                WagerRowIsSelectInCartCSS(
                                  GameID,
                                  sportData.bottomPlayOdd,
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    wagerData,
                                    rowIndex
                                  ).bottomWagerPos
                                )
                              "
                              @click="
                                goBet(
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    wagerData,
                                    rowIndex
                                  ).bottomPlayOdd,
                                  teamData,
                                  wagerData,
                                  rowIndex,
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    wagerData,
                                    rowIndex
                                  ).bottomWagerPos,
                                  $event
                                )
                              "
                            >
                              <div class="WagerCenterItem">
                                <Odd
                                  v-if="sportData.bottomPlayOdd > limit"
                                  :OddValue="sportData.bottomPlayOdd"
                                  :UniqueID="`${GameID}-1`"
                                />
                              </div>
                            </div>
                          </template>
                          <!-- With Cutline -->
                          <template v-else>
                            <div
                              class="WagerRow"
                              :class="
                                WagerRowIsSelectInCartCSS(
                                  GameID,
                                  sportData.topPlayOdd,
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    wagerData,
                                    rowIndex
                                  ).topWagerPos
                                )
                              "
                              @click="
                                goBet(
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    wagerData,
                                    rowIndex
                                  ).topPlayOdd,
                                  teamData,
                                  wagerData,
                                  rowIndex,
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    wagerData,
                                    rowIndex
                                  ).topWagerPos,
                                  $event
                                )
                              "
                            >
                              <div class="WagerItemTitle">
                                {{ sportData.topPlayMethod }}
                              </div>
                              <div :class="source.CatID == 83 ? 'WagerItem83' : 'WagerItem'">
                                <Odd
                                  v-if="sportData.topPlayOdd > limit"
                                  :OddValue="sportData.topPlayOdd"
                                  :UniqueID="`${GameID}-0`"
                                />
                              </div>
                              <!-- 彩球 -->
                              <div
                                class="WagerItemHalf"
                                v-if="source.CatID == 83 && sportData.topPlayOdd > limit"
                              >
                                {{ lotteryContinuousText(sportData, wagerData.WagerTypeID, 'top') }}
                              </div>
                            </div>
                            <div
                              class="WagerRow"
                              :class="
                                WagerRowIsSelectInCartCSS(
                                  GameID,
                                  sportData.bottomPlayOdd,
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    wagerData,
                                    rowIndex
                                  ).bottomWagerPos
                                )
                              "
                              @click="
                                goBet(
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    wagerData,
                                    rowIndex
                                  ).bottomPlayOdd,
                                  teamData,
                                  wagerData,
                                  rowIndex,
                                  $SportLib.WagerDataToShowData(
                                    teamData.SetFlag,
                                    wagerData,
                                    rowIndex
                                  ).bottomWagerPos,
                                  $event
                                )
                              "
                            >
                              <div class="WagerItemTitle">
                                {{ sportData.bottomPlayMethod }}
                              </div>
                              <div :class="source.CatID == 83 ? 'WagerItem83' : 'WagerItem'">
                                <Odd
                                  v-if="sportData.bottomPlayOdd > limit"
                                  :OddValue="sportData.bottomPlayOdd"
                                  :UniqueID="`${GameID}-1`"
                                />
                              </div>
                              <!-- 彩球 -->
                              <div
                                class="WagerItemHalf"
                                v-if="source.CatID == 83 && sportData.bottomPlayOdd > limit"
                              >
                                {{
                                  lotteryContinuousText(sportData, wagerData.WagerTypeID, 'bottom')
                                }}
                              </div>
                            </div>
                          </template>
                          <!-- 和 -->
                          <template v-if="isShowDrewOdd">
                            <template v-if="wagerData.isNoData">
                              <div class="WagerRow" />
                            </template>
                            <template v-else>
                              <div
                                class="WagerRow"
                                :class="
                                  WagerRowIsSelectInCartCSS(
                                    GameID,
                                    sportData.drewPlayOdd,
                                    $SportLib.WagerDataToShowData(
                                      teamData.SetFlag,
                                      wagerData,
                                      rowIndex
                                    ).drewWagerPos
                                  )
                                "
                                @click="
                                  goBet(
                                    $SportLib.WagerDataToShowData(
                                      teamData.SetFlag,
                                      wagerData,
                                      rowIndex
                                    ).drewPlayOdd,
                                    teamData,
                                    wagerData,
                                    rowIndex,
                                    $SportLib.WagerDataToShowData(
                                      teamData.SetFlag,
                                      wagerData,
                                      rowIndex
                                    ).drewWagerPos,
                                    $event
                                  )
                                "
                              >
                                <div class="WagerCenterItem">
                                  <Odd
                                    v-if="sportData.drewPlayOdd > limit"
                                    :OddValue="sportData.drewPlayOdd"
                                    :UniqueID="`${GameID}-2`"
                                  />
                                </div>
                              </div>
                            </template>
                          </template>
                        </template>
                      </div>
                    </td>
                  </template>
                  <!-- More -->
                  <td
                    v-if="teamData.MoreCount !== -1 && selectCatID !== GAME_CHAMPION_CAT"
                    class="GameTableHeaderMoreTD"
                    :class="isGameTableHeaderMoreSelect(teamData, rowIndex)"
                  >
                    <div
                      v-if="rowIndex === 0"
                      class="moreGame"
                      @click="moreGameClickHandler(teamData)"
                    >
                      <img src="@/assets/img/common/moreGameIcon.svg" />
                      {{ teamData.MoreCount }}
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </template>
    </template>
  </div>
</template>

<script>
  import { GAME_HOT_CAT, GAME_CHAMPION_CAT, GAME_HANDICAP_WAGERS } from '@/config';
  import mixin from './GamesTableMixin';
  import Odd from '@/components/Odd';

  export default {
    mixins: [mixin],
    components: {
      Odd,
    },
    props: {
      BestHead: {
        type: Array,
      },
      source: {
        type: Object,
        default() {
          return {};
        },
      },
      columnLimit: {
        type: Number,
        default: 10,
      },
      isCollapse: {
        type: Boolean,
      },
      index: {
        type: Number,
      },
    },
    data() {
      return {
        limit: 0.01,
        boldRenderLeftTemplate: [
          '1-0',
          '2-0',
          '2-1',
          '3-0',
          '3-1',
          '3-2',
          '4-0',
          '4-1',
          '4-2',
          '4-3',
          '0-1',
          '0-2',
          '1-2',
          '0-3',
          '1-3',
          '2-3',
          '0-4',
          '1-4',
          '2-4',
          '3-4',
        ],
        boldRenderRightTemplate: ['0-0', '1-1', '2-2', '3-3', '4-4', 'other'],
        GAME_CHAMPION_CAT,
      };
    },
    computed: {
      teamImagePath() {
        return '/timage/';
      },
      teamImages() {
        return this.$store.state.Game.teamImages;
      },
      sourceTeamData() {
        return this.source.Team;
      },
      GameList() {
        return this.$store.getters['Game/GameListFilterBySelectLeague'];
      },
      betCartList() {
        return this.$store.state.BetCart.betCartList;
      },
      moreGameEvtID() {
        if (
          this.$store.state.MoreGame.isShowMoreGame &&
          Object.keys(this.$store.state.MoreGame.teamData).length !== 0
        ) {
          return this.$store.state.MoreGame.teamData.EvtID;
        } else {
          return null;
        }
      },
      selectGameType() {
        return this.gameStore.selectGameType;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      CatMapData() {
        return this.$store.state.Game.CatMapData;
      },
      boldTeamData() {
        const newData = this.source.Team.map(this.teamDataMapLogic)
          .filter((it) => it.Wager.length !== 0)
          .map((it) => {
            const newIt = JSON.parse(JSON.stringify(it));
            newIt.boldOddMapData = this.boldOddToMapData(it.Wager[0].Odds);
            return newIt;
          });
        return newData;
      },
      boldFilterType() {
        return this.$store.state.Game.boldFilterType;
      },
      boldRenderLeftTemplateFilter() {
        return this.boldRenderLeftTemplate;
      },
      boldRenderRightTemplateFilter() {
        return this.boldRenderRightTemplate;
      },
      isBoldGame() {
        if (this.$store.state.Game.searchMode) return false;
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 3:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      halfallTemplateFilter() {
        return this.halfallTemplate;
      },
      isSingleScore() {
        if (this.$store.state.Game.searchMode) return false;
        if (this.BestHead[0].length !== 0) {
          const WagerTypeIDs = this.BestHead[0][0].WagerTypeIDs;
          const isFind = WagerTypeIDs.every((it) =>
            this.$conf.GAME_ONE_TEAM_SCORE_WAGERS.includes(it)
          );
          return isFind;
        } else {
          return false;
        }
      },
      isShowTitleBlock() {
        return this.sourceTeamData.length;
      },
      isShowPTName() {
        const findData = this.$store.state.Game.CatList.find((it) => {
          return it.GroupCatIDs.indexOf(this.selectCatID) !== -1;
        });
        // 判斷是否為棒球
        let isBaseBall = false;
        if (findData?.CatID === 101) {
          isBaseBall = true;
        }
        // 滾球棒球不能顯示投手名
        if (this.selectGameType === 2 && isBaseBall) {
          return false;
        } else {
          return true;
        }
      },
      isQuickBetEnable() {
        return this.$store.state.Game.isQuickBet.isEnable;
      },
      includePrincipal() {
        return this.$store.state.Setting.UserSetting.includePrincipal;
      },
      arrowIconJudge() {
        if (this.isCollapse) {
          return 'el-icon-arrow-down';
        } else {
          return 'el-icon-arrow-up';
        }
      },
      isFix() {
        if (this.$store.state.Game.searchMode) return false;
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 4:
              case 5:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isShowMenu() {
        if (this.$store.state.Game.searchMode) return false;
        const { selectCatID, selectWagerTypeKey } = this;
        switch (selectCatID) {
          // 足球
          case 1:
          case 31:
            switch (selectWagerTypeKey) {
              case 2:
                return true;
              default:
                return false;
            }
          // 籃球
          case 3:
          case 102:
            switch (selectWagerTypeKey) {
              // 單隊總分
              case 6:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isFixLineShow() {
        if (this.$store.state.Game.searchMode) return false;
        const { selectCatID, selectWagerTypeKey } = this;
        return (
          (selectCatID === 102 && selectWagerTypeKey === 6) ||
          (selectCatID === 101 && selectWagerTypeKey === 5)
        );
      },
    },
    methods: {
      lotteryContinuousText(sportData, WagerTypeID, pos) {
        let text = '';
        let times = 0;
        if (pos == 'top') {
          if (WagerTypeID == 104) {
            times = sportData.lotteryContinuousData.bigContinuous;
          } else if (WagerTypeID == 105) {
            times = sportData.lotteryContinuousData.oddContinuous;
          }
        } else if (pos == 'bottom') {
          if (WagerTypeID == 104) {
            times = sportData.lotteryContinuousData.smallContinuous;
          } else if (WagerTypeID == 105) {
            times = sportData.lotteryContinuousData.evenContinuous;
          }
        } else {
        }
        return times > 0 ? this.$t('ListCardItem.LotteryContinuousTimes', { times: times }) : '';
      },
      isLineShow(rowIndex) {
        const linesShow = this.$store.state.Setting.UserSetting.tableLines;
        if (rowIndex === 0) return true;
        return linesShow;
      },
      isEmphasizedTeam(eventInfo, position) {
        if (eventInfo) {
          const { Wager: wagers } = eventInfo;
          if (wagers) {
            const targetWager = wagers.find(({ WagerTypeID }) =>
              GAME_HANDICAP_WAGERS.includes(WagerTypeID)
            );
            if (targetWager) {
              const { Odds: oddsList } = targetWager;
              if (oddsList && oddsList.length) {
                const targetInfo = oddsList[0];
                if (targetInfo && targetInfo.HdpPos) {
                  if (position === targetInfo.HdpPos) {
                    if (position === 1) {
                      return targetInfo.HomeHdp !== '0' && targetInfo.HomeHdp !== 'PK';
                    } else if (position === 2) {
                      return targetInfo.AwayHdp !== '0' && targetInfo.AwayHdp !== 'PK';
                    }
                  }
                  return position === targetInfo.HdpPos;
                }
              }
            }
          }
        }
        return false;
      },
      teamWagerDataFilterLimit(teamData) {
        if (this.isShowMenu) {
          if (this.boldFilterType) {
            return teamData.Wager1.filter((it, index) => index < this.columnLimit);
          } else {
            return teamData.Wager2.filter((it, index) => index < this.columnLimit);
          }
        } else {
          return teamData.Wager.filter((it, index) => index < this.columnLimit);
        }
      },
      teamDataMapLogic(teamData) {
        const GAME_FULL_GROUPS = this.$conf.GAME_FULL_GROUPS;
        const newTeamData = JSON.parse(JSON.stringify(teamData));
        newTeamData.Wager = teamData.Wager.filter((wagerData) => {
          if (wagerData?.isNoData) {
            return false;
          } else {
            if (this.boldFilterType) {
              return GAME_FULL_GROUPS.indexOf(wagerData.WagerGrpID) !== -1;
            } else {
              return GAME_FULL_GROUPS.indexOf(wagerData.WagerGrpID) === -1;
            }
          }
        });
        return newTeamData;
      },
      boldOddToMapData(boldOdd) {
        const res = boldOdd.reduce((sum, it, index) => {
          return {
            ...sum,
            ...{
              [it.OULine]: it,
            },
          };
        }, {});
        return res;
      },
      boldTableBetBlockIsSelect(oddData, OULine) {
        const compareData = this.betCartList.find(
          (cartData) => cartData.GameID === oddData?.GameID
        );
        if (compareData && compareData.OULine === OULine) {
          return 'boldTableBetBlockSelect';
        } else {
          return '';
        }
      },
      boldOULineShow(OULine) {
        if (OULine === 'other') {
          return this.$t('Bold.Other');
        } else {
          return OULine;
        }
      },
      boldOddMapDataDrewOdds(param) {
        if (!param) return '';
        const odds = (parseFloat(param.DrewOdds) + this.$store.getters.userOddsAdjustment).toFixed(
          3
        );
        if (!odds) return '';
        if (this.includePrincipal) {
          const value = this.$lib.trunc(parseFloat(odds) + 1);
          var arrayNum = value.toString().split('.');
          if (arrayNum.length === 1) {
            return value.toString() + '.000';
          }
          if (arrayNum.length > 1) {
            const v = arrayNum[1];
            if (v.length === 1) {
              return value.toString() + '00';
            } else if (v.length === 2) {
              return value.toString() + '0';
            }
            return value;
          }
          return value;
        } else {
          return odds;
        }
      },
      goBoldBet(showOdd, oddData, teamData, event) {
        if (showOdd === undefined) {
          return;
        }
        this.$emit('AddToCart');

        const { GameType } = teamData.Wager[0];
        const GameTypeLabel = this.$store.state.Game.GameTypeList.find(
          (it) => it.key === GameType
        )?.value;

        const betInfoData = {
          LeagueID: this.source.LeagueID,
          HomeID: teamData.HomeID,
          AwayID: teamData.AwayID,
          TimeStr: teamData.ScheduleTimeStr,
          OriginShowOdd: parseFloat(showOdd),
          wagerPos: 3,
          GameTypeID: GameType,
          GameTypeLabel: GameTypeLabel,
          GameID: oddData.GameID,
          CatID: this.source.CatID,
          CatNameStr: this.source.CatNameStr,
          ModID: this.source.ModID,
          LeagueNameStr: this.source.LeagueNameStr,
          HomeTeamStr: teamData?.HomeTeamStr,
          AwayTeamStr: teamData?.AwayTeamStr,
          WagerGrpID: 10,
          WagerTypeID: 112,
          EvtID: teamData.EvtID,
          EvtStatus: teamData.EvtStatus,
          SetFlag: teamData.SetFlag,
          ...oddData,
        };

        this.$store.dispatch('BetCart/addCart', betInfoData);

        this.checkQuickBet(event);
      },
      WagerRowIsSelectInCartCSS(GameID, showOdd, wagerPos) {
        let appendCSS = '';
        if (showOdd && showOdd > this.limit) {
          appendCSS = ' WagerRowInteractive';
        }
        const compareData = this.betCartList.find((cartData) => cartData.GameID === GameID);
        if (compareData && compareData.wagerPos === wagerPos) {
          appendCSS += ' WagerRowIsSelect';
        }
        return appendCSS;
      },
      wagerRoIndexToGameID(wagerData, rowIndex) {
        if (
          wagerData?.isNoData ||
          wagerData.Odds[rowIndex] === undefined ||
          wagerData.Odds[rowIndex].Status !== 1
        ) {
          return null;
        } else {
          return wagerData.Odds[rowIndex].GameID;
        }
      },
      goBet(showOdd, teamData, wagerData, rowIndex, wagerPos, event) {
        if (!showOdd || !teamData || !wagerData) {
          return;
        }
        if (showOdd <= this.limit) {
          return;
        }

        this.$emit('AddToCart');

        const { CatID, CatNameStr, ModID } = this.source;
        const { selectWagerTypeKey } = this;
        const { GameType } = wagerData;

        let {
          SetFlag = true,
          HomeTeamStr = '',
          AwayTeamStr = '',
          LiveTV = '',
          LiveTVLink = '',
        } = teamData;

        const { HomePtAndNation, AwayPtAndNation } = teamData;
        if (HomePtAndNation && HomePtAndNation.length) {
          HomeTeamStr = HomePtAndNation[0] + ' / ' + HomePtAndNation[2];
        }
        if (AwayPtAndNation && AwayPtAndNation.length) {
          AwayTeamStr = AwayPtAndNation[0] + ' / ' + AwayPtAndNation[2];
        }

        const selectGameTypeLabel = this.$store.state.Game.GameTypeList.find(
          (item) => item.key === GameType
        )?.value;

        const WagerGrpName = this.BestHead[0]
          .find((headData) => {
            return (
              headData.WagerGrpIDs.indexOf(wagerData.WagerGrpID) !== -1 &&
              headData.WagerTypeIDs.indexOf(wagerData.WagerTypeID) !== -1
            );
          })
          ?.WagerGrpName.trim();

        let WagerTypeName = this.$store.getters['Game/selectWagerTypeName'];
        if (this.selectCatID === GAME_HOT_CAT) {
          WagerTypeName = '';
        }

        let ItemName = '';

        if (selectWagerTypeKey !== 1) {
          // 首尾分
          if ([117, 118].includes(wagerData.WagerTypeID)) {
            ItemName = this.BestHead[0].find((headData) => {
              return (
                headData.WagerGrpIDs.indexOf(wagerData.WagerGrpID) !== -1 &&
                headData.WagerTypeIDs.indexOf(wagerData.WagerTypeID) !== -1
              );
            })?.showName;
          } else if (WagerGrpName) {
            // 籃球 下半場
            if ([3, 102].includes(CatID) && selectWagerTypeKey === 2) {
              ItemName = `${WagerGrpName}`;
            } else if (WagerTypeName) {
              ItemName = `${WagerGrpName} ${WagerTypeName}`;
            } else {
              ItemName = WagerGrpName;
            }
          } else {
            // 足球 角球
            // 籃球 單隊總分
            if ([1, 11, 21].includes(wagerData.WagerGrpID)) {
              ItemName = `上半 ${WagerTypeName}`;
            } else {
              ItemName = WagerTypeName;
            }
          }
        } else {
          ItemName = WagerGrpName;
        }

        if (!SetFlag && ![131, 132, 133].includes(wagerData.WagerTypeID)) {
          [HomeTeamStr, AwayTeamStr] = [AwayTeamStr, HomeTeamStr];
        }

        const betInfoData = {
          GameTypeID: GameType,
          GameTypeLabel: selectGameTypeLabel,
          CatID,
          CatNameStr,
          ModID,
          LeagueID: this.source.LeagueID,
          LeagueNameStr: this.source.LeagueNameStr,
          EvtID: teamData.EvtID,
          EvtStatus: teamData.EvtStatus,
          HomeID: teamData.HomeID,
          HomeScore: teamData.HomeScore,
          HomeTeamStr,
          AwayID: teamData.AwayID,
          AwayScore: teamData.AwayScore,
          AwayTeamStr,
          SetFlag: teamData.SetFlag,
          TimeStr: teamData.ScheduleTimeStr,
          WagerTypeID: wagerData.WagerTypeID,
          WagerGrpID: wagerData.WagerGrpID,
          GameID: wagerData.Odds[rowIndex].GameID,
          OriginShowOdd: parseFloat(showOdd),
          wagerPos,
          ItemName,
          ParlayFlag: teamData.ParlayFlag,
          ...wagerData.Odds[rowIndex],
          LiveTV,
          LiveTVLink,
        };

        this.$store.dispatch('BetCart/addCart', betInfoData);
        this.checkQuickBet(event);
      },
      isFixWagerSelectd(gameID) {
        let cssClass = ' WagerRowInteractive';
        const betData = this.betCartList.find((betData) => betData.GameID === gameID);
        if (betData) {
          cssClass += ' WagerRowIsSelect';
        }
        return cssClass;
      },
      goFixBet(odds, wagerData, teamData, event) {
        const [oddsData] = wagerData.Odds;
        const { GameID } = oddsData;

        if (!odds || odds <= this.limit) return;

        this.$emit('AddToCart');

        const { GameType } = wagerData;

        const GameTypeLabel = this.gameStore.GameTypeList.find(
          (item) => item.key === GameType
        )?.value;

        const { WagerTypeID, WagerGrpID } = wagerData;
        const [headList] = this.BestHead;
        const head = headList.find((headData) => {
          const { WagerTypeIDs, WagerGrpIDs, OULine } = headData;
          return (
            WagerTypeIDs.includes(WagerTypeID) &&
            (WagerGrpIDs.includes(WagerGrpID) || WagerGrpIDs.includes(128)) &&
            OULine === oddsData.OULine
          );
        });

        const { WagerGrpName, showName: OULineName } = head;
        const WagerTypeName = this.$store.getters['Game/selectWagerTypeName'];
        let ItemName = '';
        if ([119].includes(WagerTypeID) && [1, 11, 21].includes(WagerGrpID)) {
          ItemName = `${WagerGrpName} ${WagerTypeName}`;
        } else {
          ItemName = `${WagerTypeName}`;
        }

        const { CatID, CatNameStr, LeagueID, LeagueNameStr, ModID } = this.source;
        const {
          HomeID,
          HomeTeamStr = '',
          AwayID,
          AwayTeamStr = '',
          SetFlag,
          ScheduleTimeStr: TimeStr,
          EvtID,
          EvtStatus,
          LiveTV = '',
          LiveTVLink = '',
        } = teamData;

        const betData = {
          GameTypeID: GameType,
          GameTypeLabel,
          CatID,
          CatNameStr,
          ModID,
          LeagueID,
          LeagueNameStr,
          HomeID,
          HomeTeamStr,
          AwayID,
          AwayTeamStr,
          SetFlag,
          TimeStr,
          EvtID,
          EvtStatus,
          WagerGrpID,
          WagerTypeID,
          GameID,
          OriginShowOdd: parseFloat(odds),
          wagerPos: 3,
          ItemName,
          OULineName,
          LiveTV,
          LiveTVLink,
          ...oddsData,
        };

        this.$store.dispatch('BetCart/addCart', betData);
        this.checkQuickBet(event);
      },
      checkQuickBet(event) {
        if (this.isQuickBetEnable) {
          this.$nextTick(() => {
            const clickTarget = event.target.getBoundingClientRect();
            this.$store.commit('BetCart/showQuickBetData', {
              isShow: true,
              x: clickTarget.left + clickTarget.width / 2,
              y: clickTarget.top,
            });
          });
        }
      },
      clickArrow() {
        this.$emit(
          'collapseChange',
          this.$lib.leagueDataToUniqueKey(this.source.LeagueID, this.source.Nu)
        );
      },
      moreGameClickHandler(teamData) {
        this.$store.dispatch('MoreGame/openMoreGameList', {
          teamData,
        });
      },
      addFavoriteHandler(EvtID) {
        this.$store.dispatch('Setting/addFavorites', EvtID);
      },
      starCSSJudge(EvtID) {
        if (this.$store.state.Setting.UserSetting.favorites.indexOf(EvtID) > -1) {
          return 'starActive';
        } else {
          return '';
        }
      },
      isGameTableHeaderMoreSelect(teamData, rowIndex) {
        if (teamData.EvtID === this.moreGameEvtID && rowIndex === 0) {
          return 'GameTableHeaderMoreSelect';
        } else {
          return '';
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';
  @import './GameTable.scss';

  .team-image {
    position: relative;
    top: 3px;
    width: 16px;
    height: 16px;
  }

  #app[data-theme^='light'] {
    .GameCollapse {
      border-bottom: 1px solid #d0d0d0;
      table {
        background-color: white;
        color: black;
        tbody {
          .FirstCatNameBlock {
            border-bottom: 1px solid #ddd;
            .leftTimeBlock {
              .timeRow {
                color: #888;
              }
            }
          }
        }
      }
      table {
        background-color: #f7f7f7;
        &:hover {
          background-color: #fff;
        }
      }
      .collapseTitleBlock {
        background-color: #ddd;
        color: black;
        &:hover {
          background-color: #ccc;
        }
      }
      .boldTableBetList {
        .boldTableBetBlockSelect {
          background-color: #ffd5d5;
        }
      }
      .WagerList {
        border-left: 1px solid #ddd;
        .WagerRow {
          border-bottom: 1px solid #ddd;
        }
      }
      .GameTableHeaderMoreTD {
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
      }
      .WagerRowInteractive {
        &:hover {
          background-color: #ffe1ae;
        }
      }
      .WagerRowIsSelect {
        background-color: #ffd5d5;
      }
      .moreGame {
        &:hover {
          background-color: #e8e8e8;
        }
      }
      .GameTableHeaderMoreSelect {
        background-color: #cce6ff;
      }
    }
  }
  #app[data-theme^='dark'] {
    .GameCollapse {
      border-bottom: 1px solid #4f4f4f;
      table {
        background-color: #333;
        color: white;
        tbody {
          .FirstCatNameBlock {
            border-bottom: 1px solid #5f5f5f;
            .leftTimeBlock {
              .timeRow {
                color: #bbb;
              }
            }
          }
        }

        &:hover {
          background-color: #000;
        }
      }

      .collapseTitleBlock,
      .boldTableTitleBlock {
        background-color: #444;
        color: white;
        .timeText {
          color: white;
        }

        &:hover {
          background-color: #222;
        }
      }
      .boldTableBetList {
        .boldTableBetBlockSelect {
          background-color: white;
          color: black;
        }
      }
      .WagerList {
        border-left: 1px solid #5f5f5f;
        .WagerRow {
          border-bottom: 1px solid #5f5f5f;
        }
      }
      .GameTableHeaderMoreTD {
        border-left: 1px solid #5f5f5f;
        border-bottom: 1px solid #5f5f5f;
      }
      .WagerRowInteractive {
        &:hover {
          background-color: #777;
        }
      }
      .WagerRowIsSelect {
        background-color: #fff;

        .WagerItemTitle {
          color: #000;
        }
        .WagerItem83 {
          color: #000;
        }
        .WagerItem {
          color: #000;
        }
      }
      .moreGame {
        &:hover {
          background-color: #777;
        }
      }
      .GameTableHeaderMoreSelect {
        background-color: #336699;
      }
      .boldTableBetBlock {
        border-left: 1px solid #5f5f5f;
        border-bottom: 1px solid #5f5f5f;
        background-color: #585858;
        color: white;
        .betBlockBottom {
          color: #3fa381;
        }
        &:hover {
          background-color: #777;
        }
      }
    }
  }
  @mixin paddingTopAndBottom() {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .GameCollapse {
    .light {
      color: #ff8500 !important;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    $starSize: 19px;
    .star {
      width: $starSize;
      height: $starSize;
      background-size: 100% auto;
      background: url(~@/assets/img/pc/icon_star.svg) no-repeat center bottom;
      cursor: pointer;
    }
    .live {
      width: $starSize;
      filter: brightness(0.6);
      cursor: pointer;

      &:hover {
        filter: brightness(0.4);
      }
    }
    .more {
      width: $starSize;
      //filter: brightness(0.6);
      cursor: pointer;

      &:hover {
        filter: brightness(0.4);
      }
    }
    .starActive {
      width: $starSize;
      height: $starSize;
      background-size: 100% auto;
      background: url(~@/assets/img/pc/icon_star.svg) no-repeat center top;
    }
    .collapseTitleBlock {
      width: 100%;
      height: 35px;
      color: #000;
      display: flex;
      cursor: pointer;
      .leftArrowBlock {
        width: 50px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        i {
          font-size: 15px;
          margin-top: -2px;
        }
      }
      .rightLeagueNameBlock {
        display: flex;
        align-items: center;
      }
    }
    .boldTablePanel {
      width: 100%;
      .boldTableTitleBlock {
        background-color: #ddd;
        height: 30px;
        display: flex;
        align-items: center;
        .timeBlock {
          display: flex;
          .timeText {
            margin-left: 10px;
            color: #777;
          }
        }
        .teamBlock {
          display: flex;
          margin-left: 10px;
          .teamText {
            margin-left: 8px;
          }
        }
        .star {
          margin-left: 10px;
        }
      }
      .boldTableBetList {
        display: flex;
        width: 100%;
        .boldTableBetBlock {
          display: flex;
          flex-wrap: wrap;
          text-align: center;
          background-color: white;
          border-left: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          cursor: pointer;
          &:hover {
            background-color: #ffe1ae;
          }
          .betBlockTop {
            width: 100%;
            height: 30px;
            line-height: 30px;
          }
          .betBlockBottom {
            width: 100%;
            height: 30px;
            line-height: 30px;
            color: #3fa381;
            font-weight: bold;
          }
        }
        .boldTableBetListLeftContainer {
          display: flex;
          width: 62.5%;
          flex-wrap: wrap;
          .boldTableBetBlock {
            width: 10%;
          }
        }
        .boldTableBetListRightContainer {
          display: flex;
          width: 37.5%;
          flex-wrap: wrap;
          .boldTableBetBlock {
            width: 16.6666%;
            display: flex;
            align-items: center;
            .betBlockTop {
              line-height: 55px;
            }
            .betBlockBottom {
              line-height: 0;
            }
          }
        }
      }
    }
    .normalTablePanel {
      width: 100%;
      border-collapse: collapse;
      tbody {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        tr {
          width: 100%;
          display: flex;
        }
        .FirstCatNameBlock {
          display: flex;
          //@include paddingTopAndBottom();
          .leftTimeBlock {
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            .timeRow {
              width: 100%;
              text-align: center;
            }
          }
          .centerTeamBlock {
            width: calc(100% - 50px - 30px);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .teamRow {
              width: 100%;
              height: fit-content;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 13px;
              line-height: 18px;
              .teamPt {
                color: gray;
              }

              .team-name {
                &.emphasized {
                  color: #3fa381;
                }
              }
            }
          }
          .scoreBlock {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            gap: 1rem;
            padding: 5px;
          }
          .rightFavoriteBlock {
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column nowrap;
            gap: 4px;
            .sell {
              width: 20px;
              height: 20px;
            }
          }
        }
        .GameTableHeaderOtherTD,
        .GameTableHeaderMoreTD {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          white-space: nowrap;
          .borderWhiteBlock {
            background-color: rgba(255, 255, 255, 0.25);
            height: 15px;
            width: 1px;
          }
        }

        .GameTableHeaderOtherTD {
          height: 100%;
          .WagerList {
            width: 100%;
            height: 100%;
            min-width: 80px;

            &.fix {
              min-width: 60px;
            }

            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .WagerRow {
              display: flex;
              width: 100%;
              height: 30px;

              &.fix {
                height: 100%;
              }
              .WagerItemTitle {
                width: 50%;
                text-align: left;
                line-height: 30px;
                padding-left: 5px;
                pointer-events: none;
                &:first-child {
                  padding-right: 5px;
                  text-align: right;
                }
              }
              .WagerItem {
                width: 50%;
                text-align: left;
                line-height: 30px;
                padding-left: 5px;
                pointer-events: none;
                &:first-child {
                  padding-right: 5px;
                  text-align: right;
                }
              }
              .WagerItem83 {
                width: 20%;
                text-align: left;
                line-height: 30px;
                padding-left: 5px;
                pointer-events: none;
                &:first-child {
                  padding-right: 5px;
                  text-align: right;
                }
              }
              .WagerItemHalf {
                width: 25%;
                text-align: left;
                line-height: 30px;
                padding-left: 5px;
                pointer-events: none;
                &:first-child {
                  padding-right: 5px;
                  text-align: right;
                }
              }
              .WagerCenterItem {
                width: 100%;
                text-align: center;
                line-height: 30px;
                pointer-events: none;

                &.fix {
                  margin-top: 13px;
                }
              }
            }
            .WagerRowInteractive {
              cursor: pointer;
            }
          }
        }
        .GameTableHeaderMoreTD {
          width: 60px;
          min-width: 60px;
          white-space: nowrap;
        }
        .moreGame {
          cursor: pointer;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          img {
            max-width: 0.9rem;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
</style>
